import { userTimeZoneToCountryCode } from '../location';
import { subscribeToNewsletter, updateCart, getDiscount } from './mutations';
import getOrCreateReferral from './mutations/get-or-create-referral';
import { getOrder, getProducts, getReferralLink } from './queries';

import {
    AddToCartProps,
    AddDiscountProps,
    AddProductProps,
    Variables,
    GetProductsProps,
    SubcribeToNewsletterProps,
    Cart,
    CheckDiscountProps,
    CheckDiscountReturn,
    PriceLineItem,
    GetOrderProps,
    Order,
    OrderContent,
    GetOrCreateReferralCodeProps,
    ReferralContent,
    Referral,
    GetReferralLinkProps,
    ReferralLink,
} from './types';

class PlaybrushClient {
    endpoint: string;

    constructor(endpoint: string) {
        this.endpoint = endpoint;
    }

    getHeaders(): HeadersInit {
        return ({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
    }

    getBaseConfig(): RequestInit {
        return ({ method: 'POST', headers: this.getHeaders() });
    }

    checkDiscount(variables: CheckDiscountProps): Promise<CheckDiscountReturn | {}> {
        return this
            .post<{ couponCheck: CheckDiscountReturn }>(getDiscount, variables)
            .then(data => {
                const { couponCheck } = (data || {}) as { couponCheck: CheckDiscountReturn };
                return couponCheck;
            })
    }

    subscribeToNewsletter(
        variables: SubcribeToNewsletterProps,
        callback: () => void,
        errorCallback: (e: string) => void,
    ): Promise<void> {
        return this
            .post<{ newsletterSubscribe?: { success?: string } }>(subscribeToNewsletter, variables)
            .then(data => {
                const { newsletterSubscribe } = (data || {}) as { newsletterSubscribe?: { success?: string } };
                const { success } = newsletterSubscribe || {};
                return success
                    ? callback && callback()
                    : errorCallback && errorCallback(
                        'Diese E-Mail-Adresse stimmt nicht mit dem autorisierten Format überein'
                    );
            })
    }

    getReferralLink(variables: GetReferralLinkProps): Promise<ReferralContent> {
        return this
            .post<Referral>(getReferralLink, variables)
            .then(data => {
                const { referralLink } = (data || {}) as ReferralLink;
                return referralLink?.couponCode;
            })
    }

    getOrCreateReferralCode(variables: GetOrCreateReferralCodeProps): Promise<ReferralContent> {
        return this
            .post<Referral>(getOrCreateReferral, variables)
            .then(data => {
                const { referralCreateOrGet } = (data || {}) as Referral;
                return referralCreateOrGet?.couponCode;
            })
    }

    getOrder(variables: GetOrderProps): Promise<{ order: OrderContent, cart: Cart }> {
        return this
            .post<Order>(getOrder, variables)
            .then(data => {
                const { orderInfo } = (data || {}) as Order;
                return { order: orderInfo?.order, cart: orderInfo?.cart };
            })
    }

    getProducts(variables: GetProductsProps): Promise<Record<string, unknown>> {
        return this.post<any>(getProducts, variables)
    }

    addProduct({ itemList, cartId, frontendData, currency, language, country }: AddProductProps): Promise<Cart> {
        return this.addToCart({ itemList, cartId, frontendData, currency, language, country });
    }

    addDiscount({ cartId, couponCode, itemList, currency, language, country }: AddDiscountProps): Promise<Cart> {
        return this.addToCart({ cartId, couponCode, itemList, currency, language, country });
    }

    checkCart({ lineItems, cartId, frontendData, currency, language, country }: any): Promise<any> {
        return this
            .post<any>(updateCart, {
                cartId,
                frontendData: frontendData ? JSON.stringify(frontendData) : '{}',
                currency,
                language,
                country: userTimeZoneToCountryCode(),
                itemList: lineItems.map((lineItem: PriceLineItem) => ({
                    lineId: lineItem.lineId,
                    quantity: lineItem.quantity,
                })),
            })
            .then(data => data);
    }

    private addToCart(variables: AddToCartProps): Promise<Cart> {
        return this
            .post<{ cart: Cart }>(updateCart, variables)
            .then(data => {
                const { cart } = (data || {}) as { cart: Cart };
                return cart;
            })
    }

    private post<T>(
        query: string,
        variables: Variables,
        userConfig = {},
    ): Promise<T | {}> {
        const config: RequestInit = {
            ...this.getBaseConfig(),
            body: JSON.stringify({
                query,
                variables,
            }),
            ...userConfig,
        };
        try {
            return fetch(this.endpoint, config)
                .then(res => res.json())
                .then(response => {
                    const { data } = response || {};
                    return data;
                })
        } catch (e) {
            console.error(e);
            return new Promise((resolve) => resolve({}));
        }
    }
}

const Client = new PlaybrushClient(process.env.GATSBY_ECOMMERCE_ENDPOINT || "https://pbwebshop.playbrush.com/gql/v1/graphql");

export default Client;