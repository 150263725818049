import { constants } from '@constants';
import { getFromSessionStorage, setToSessionStorage, userTimeZoneToCountryCode } from '@utils';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import Button from '../button';

const Overlay = styled.div`
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, .4);
    z-index: 99;
`

const Modal = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 100dvh;
    background: rgba(0, 0, 0, .4);
    z-index: 99;
    overflow-y: scroll;
    width: 100%;
    max-width: 600px;
`

const InnerModal = styled.div`
    position: relative;
    background: #E2FFF1;
    padding: 48px 16px 24px;
    margin: 0 auto;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`

const Heading = styled.h2`
    margin: 0 0 1.5rem;
`

const Body = styled.p`
    margin: 0 0 2rem;
`

const ButtonWrapper = styled.div`
    margin: 0 0 .5rem;
    display: flex;
    flex-direction: column;
`

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    
    @media screen and (min-width: 480px) {
        top: 18px;
        right: 16px;
    }
`

const LanguageModal = () => {
    const hasPreviousSelection = getFromSessionStorage(constants.cookies.LANGUAGE_MODAL_COOKIE);
    const userCountry = userTimeZoneToCountryCode();
    const isInFrance = userCountry === 'FR';
    const isInItaly = userCountry === 'IT';
    const isInFranceOrItaly = isInFrance || isInItaly;
    const [open, setOpen] = useState(false);

    const closeModal = () => {
        setToSessionStorage(constants.cookies.LANGUAGE_MODAL_COOKIE, 1);
        setOpen(false);
    };

    const goTo = (link: string) => {
        setToSessionStorage(constants.cookies.LANGUAGE_MODAL_COOKIE, 1);
        window.location.href = link;
    }

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [open])

    useEffect(() => {
        if (isInFranceOrItaly && !hasPreviousSelection) {
            setOpen(true);
        }
    }, [])
    
    return (
        <>
            {open ? createPortal(
                <>
                    <Overlay></Overlay>
                    <Modal>
                        <InnerModal>
                            <CloseButton type="button" onClick={closeModal}>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.6928 5.70711C6.08332 5.31658 6.71648 5.31658 7.10701 5.70711L25.4918 24.0919C25.8823 24.4824 25.8823 25.1156 25.4918 25.5061C25.1013 25.8966 24.4681 25.8966 24.0776 25.5061L5.6928 7.12132C5.30227 6.7308 5.30227 6.09763 5.6928 5.70711Z"
                                        fill="#042751"
                                    />
                                    <path
                                        d="M5.70711 25.5069C5.31658 25.1164 5.31658 24.4832 5.70711 24.0927L24.0919 5.70792C24.4824 5.3174 25.1156 5.3174 25.5061 5.70792C25.8966 6.09845 25.8966 6.73161 25.5061 7.12214L7.12132 25.5069C6.7308 25.8974 6.09763 25.8974 5.70711 25.5069Z"
                                        fill="#042751"
                                    />
                                </svg>
                            </CloseButton>
                            <Content>
                                {isInFrance && (
                                    <>
                                        <Heading>Salut!</Heading>
                                        <Body>
                                            Nous voyons que vous venez de France. Souhaitez-vous être redirigé vers notre site Web en français?
                                        </Body>
                                        <ButtonWrapper>
                                            <Button onClick={() => goTo('https://www.sunstargum.com/fr-fr/soins-et-produits-dentaires.html')}>
                                                Aller sur le site français
                                            </Button>
                                        </ButtonWrapper>
                                        <Button buttonStyle={'outline'} onClick={closeModal}>
                                            Continuez vos achats
                                        </Button>
                                    </>
                                )}
                                {isInItaly && (
                                    <>
                                        <Heading>Ciao!</Heading>
                                        <Body>
                                            Vediamo che vieni da noi dall'Italia. Vuoi essere portato sul nostro sito italiano?
                                        </Body>
                                        <ButtonWrapper>
                                            <Button onClick={() => goTo('https://www.sunstargum.com/it-it/prodotti.html')}>
                                                Vai al sito italiano
                                            </Button>
                                        </ButtonWrapper>
                                        <Button buttonStyle={'outline'} onClick={closeModal}>
                                            Continua a fare acquisti
                                        </Button>
                                    </>
                                )}
                            </Content>
                        </InnerModal>
                    </Modal>
                </>,
                document.body
            ) : null}
        </>
    );
}

export default LanguageModal;
